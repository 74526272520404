.TableHoverEffect tr:hover{
    transform: scale(1.01);
    transform-origin: top left;
    cursor: pointer;
    background-color: #f3f3f3 !important;
}
.TableHoverEffect tr{
    transition: 0.08s linear;
}

.Toastify__toast-container {
    min-width: 600px !important;
}